<template>
	<div>
		<b-modal 
            size="full" 
            scrollable 
			centered
            v-model="ux.show" 
            ok-only ok-title="Chiudi"
            :title="`ESERCIZIO: ${fase.nome} del ${date}`"
            @hide="close()" @close="close()" @ok="close()" @cancel="close()" 
        >
			<div>

				<div class="row">

					<div class="col-sm-12 col-md-6 mb-3">
						<img v-if="fase.entity.exercise_id && fase.entity.esercizio && fase.entity.esercizio.canvas_url" class="img-fluid" :src="fase.entity.esercizio.canvas_url" />
						<img v-else class="img-fluid" src="http://via.placeholder.com/500?text=1" />
					</div>
	
					<div class="row col-sm-12 col-md-6 mb-3">
						
						<div class="col-sm-12 col-md-6 mb-3">
							<single-data-card 
								:label="`Nome Drill GPS`"
								:data="fase.entity.drill_gps"
							/>
						</div>
						
						<div class="col-sm-12 col-md-6 mb-3">
							<single-data-card 
								:label="`Esercitazione`"
								:data="fase.entity.drill.name"
							/>
						</div>
												
						<div class="col-12 mb-3">
							<single-data-card
								:label="`Descrizione`"
								:data="fase.entity.description"
							/>
						</div>

					</div>

					<div class="col-sm-12 col-md-4 mb-3">
						<single-data-card 
							:label="`Totale Giocatori`"
							:data="fase.entity.players"
						/>
					</div>
					
					<div class="col-sm-6 col-md-2 mb-3">
						<single-data-card 
							:label="`G1`"
							:data="fase.entity.g1"
						/>
					</div>
					
					<div class="col-sm-6 col-md-2 mb-3">
						<single-data-card 
							:label="`G2`"
							:data="fase.entity.g2"
						/>
					</div>
					
					<div class="col-sm-6 col-md-2 mb-3">
						<single-data-card 
							:label="`G3`"
							:data="fase.entity.g3"
						/>
					</div>
					
					<div class="col-sm-6 col-md-2 mb-3">
						<single-data-card 
							:label="`G4`"
							:data="fase.entity.g4"
						/>
					</div>

					<div class="col-sm-6 col-md-3 mb-3">
						<single-data-card 
							:label="`Min. esercitazione`"
							:data="fase.entity.training_minutes"
						/>
					</div>
					
					<div class="col-sm-6 col-md-3 mb-3">
						<single-data-card 
							:label="`N° Serie esercitazione`"
							:data="fase.entity.sets"
						/>
					</div>
					
					<div class="col-sm-6 col-md-3 mb-3">
						<single-data-card 
							:label="`Min. recupero esercitazione`"
							:data="fase.entity.recovery_minutes"
						/>
					</div>
					
					<div class="col-sm-6 col-md-3 mb-3">
						<single-data-card 
							:label="`Sec. recupero esercitazione`"
							:data="fase.entity.recovery_seconds"
						/>
					</div>
					
					<div class="col-sm-6 col-md-3 mb-3">
						<single-data-card 
							:label="`N° recupero esercitazione`"
							:data="fase.entity.recoveries"
						/>
					</div>
					
					<div class="col-sm-6 col-md-3 mb-3">
						<single-data-card 
							:label="`Lunghezza campo`"
							:data="fase.entity.campo_lunghezza"
						/>
					</div>

					<div class="col-sm-6 col-md-3 mb-3">
						<single-data-card 
							:label="`Larghezza campo`"
							:data="fase.entity.campo_larghezza"
						/>
					</div>
					
					<div class="col-sm-6 col-md-3 mb-3">
						<single-data-card 
							:label="`Densità campo`"
							:data="getDensitaCampo(fase.entity)"
						/>
					</div>
					
					<div class="col-sm-6 col-md-3 mb-3">
						<single-data-card 
							:label="`Jolly`"
							:data="fase.entity.jolly"
						/>
					</div>
					
					<div class="col-sm-6 col-md-3 mb-3">
						<single-data-card 
							:label="`Sponde`"
							:data="fase.entity.sponde"
						/>
					</div>
					
					<div class="col-sm-4 col-md-2 mb-3">
						<single-data-card 
							:label="`Portieri`"
							:data="fase.entity.portieri"
						/>
					</div>
					
					<div class="col-sm-4 col-md-2 mb-3">
						<single-data-card 
							:label="`Porte grandi`"
							:data="fase.entity.porte_grandi"
						/>
					</div>
					
					<div class="col-sm-4 col-md-2 mb-3">
						<single-data-card 
							:label="`Porte piccole`"
							:data="fase.entity.porte_piccole"
						/>
					</div>

				</div>


				<hr />

                <!-- <tools-finder 
					v-if="auth.isUserEnable('tools_prevision') && fase.entity.exercise_id" :fase="fase"
					:pos="1"
					:sessionDataLayer="{
						fase_1: fase.entity.esercizio
					}"
				>
				</tools-finder> -->
            </div>
			<template #modal-footer>
				<b-row class="w-100">
					<b-col class="text-right">
						<b-button-group>
							<b-button class="bg-primary" :to="{ path: `/football/sessione/${fase.sessione_id}/${fase.sessione.sessione_tipo_glo_id}` }">Apri sessione originale</b-button>
							<b-button class="bg-secondary" @click.prevent="close()">Chiudi</b-button>
						</b-button-group>
					</b-col>
				</b-row>
			</template>
		</b-modal>
	</div>
</template>

<script>

import _ from 'lodash'
var Auth = require('@/auth.js');

export default {
	mixins: [],
	components: {
		ToolsFinder: require('@/components/layout/Sessioni/Football/ToolsFinder.vue').default,
		SingleDataCard: require('@/components/layout/Partials/SingleDataCard.vue').default,
	},
	watch: {
		'ux.show': {
			deep: true,
			handler: function() {}
		}
	},
	created: function() {
		
	},
	props: [ 'fase', 'date' ],
	data: function() {
		return {
			ux: {
				show: true,
				wait: false
			}
		}
	},
	
	computed: {
		auth: function() {
			return Auth;
		},

		_: function() {
			return _;
		}
	},
	methods: {
		close: function() {
			this.ux.show = false;
			this.$emit('close');
		},
		show: function() {
			this.ux.show = true;
		},
		getDensitaCampo(phase) {
			var densita = "";
			if(phase.players > 0 && phase.campo_lunghezza > 0 && phase.campo_larghezza > 0) {
				densita = (phase.campo_lunghezza * phase.campo_larghezza) / phase.players;
				densita = Math.round(densita * 100) / 100;
			}
			return densita;
		},
	}
}

</script>
