<template>
	<b-card title="Dettagli tiri open play">
		<b-table-simple small striped bordered style="font-size:0.9rem">
			<thead>
				<tr>
					<th title="Player"><a href="" @click.prevent="setOrdering('player')">Player</a> <span v-if="'player' == ordering.key">{{ ordering.order == -1 ? '↥' : '↧' }}</span></th>
					<th title="Position"><a href="" @click.prevent="setOrdering('position')">Position</a> <span v-if="'position' == ordering.key">{{ ordering.order == -1 ? '↥' : '↧' }}</span></th>
					<th title="Games played"><a href="" @click.prevent="setOrdering('gamePlayed')">Games played</a> <span v-if="'gamePlayed' == ordering.key">{{ ordering.order == -1 ? '↥' : '↧' }}</span></th>
					<th title="Minutes"><a href="" @click.prevent="setOrdering('minutes')">Minutes</a> <span v-if="'minutes' == ordering.key">{{ ordering.order == -1 ? '↥' : '↧' }}</span></th>
					<th title="Age"><a href="" @click.prevent="setOrdering('birthDate')">Age</a> <span v-if="'birthDate' == ordering.key">{{ ordering.order == -1 ? '↥' : '↧' }}</span></th>
					<th title="Preferred Foot"><a href="" @click.prevent="setOrdering('foot')">Pref Foot</a> <span v-if="'foot' == ordering.key">{{ ordering.order == -1 ? '↥' : '↧' }}</span></th>
					<th title="ShotRL"><a href="" @click.prevent="setOrdering('shotRL')">ShotRL</a> <span v-if="'shotRL' == ordering.key">{{ ordering.order == -1 ? '↥' : '↧' }}</span></th>
					<th title="ShotR"><a href="" @click.prevent="setOrdering('shotR')">ShotR</a> <span v-if="'shotR' == ordering.key">{{ ordering.order == -1 ? '↥' : '↧' }}</span></th>
					<th title="ShotL"><a href="" @click.prevent="setOrdering('shotL')">ShotL</a> <span v-if="'shotL' == ordering.key">{{ ordering.order == -1 ? '↥' : '↧' }}</span></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="player in sorted_players">
					<td>{{ player.firstName + ' ' + player.lastName }}</td>
					<td>{{ player.position }}</td>
					<td>{{ player.gamePlayed }}</td>
					<td>{{ player.minutes }}</td>
					<td>{{ player.birthDate | age }}</td>
					<td>{{ player.foot }}</td>
					<td>{{ player.shotRL }}</td>
					<td>{{ player.shotR }}</td>
					<td>{{ player.shotL }}</td>
				</tr>
			</tbody>
		</b-table-simple>
	</b-card>
</template>

<script>
import moment from 'moment'
export default {
	props: ['players'],
	data: function() {
		return {
			ordering: { key: 'shotRL', order: 1 },
		}
	},
	methods: {
		setOrdering: function(key) {
			if(this.ordering.key != key) {
				this.ordering.key = key;
				this.ordering.order = -1;
			} else {
				this.ordering.order *= -1;
			}
		},
	},
	computed: {
		sorted_players: function() {
			return _.orderBy(this.players, [this.ordering.key], [this.ordering.order == -1 ? 'asc' : 'desc']);
		},
	},
	filters: {
		age: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return moment().diff(date, 'years'); 
			}
			return "";
		}
	},
}
</script>
