<template>
	<b-card title="Report Skills Difensive">
		<b-table-simple small striped bordered responsive v-if="myStats" style="font-size:0.9rem">
			<thead>
				<tr>
					<th style="min-width:200px;"><a href="" @click.prevent="setOrdering('team')">Team</a></th>
					<th class="text-center">AVG</th>
					<th class="text-center" v-for="item in myStats" :style="item.optaId == team.opta.chiave ? 'background:#00FFFF' : ''"><b-img :src="item.logo_url ? item.logo_url : item.logo" :height="30"></b-img><div>{{ item.name }}</div></th>
				</tr>
			</thead>
			<tbody>
				<tr>	
					<td><a href="" @click.prevent="setOrdering('falliFatti')">Falli fatti</a> <span v-if="'falliFatti' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats.average.falliFatti }}</td>
					<td class="text-center" v-for="item in myStats" :style="'background:' + getBgValueStats('falliFatti', item.optaId)">{{ item.falliFatti }}</td>				
				</tr>
				<tr>	
					<td><a href="" @click.prevent="setOrdering('tackles')">Tackles</a> <span v-if="'tackles' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats.average.tackles }}</td>
					<td class="text-center" v-for="item in myStats" :style="'background:' + getBgValueStats('tackles', item.optaId)">{{ item.tackles }}</td>				
				</tr>
				<tr>	
					<td><a href="" @click.prevent="setOrdering('possessiConcessiMetaDifensiva')">Possessi Individuali Concessi Metà Campo Difensiva</a> <span v-if="'possessiConcessiMetaDifensiva' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats.average.possessiConcessiMetaDifensiva }}</td>
					<td class="text-center" v-for="item in myStats" :style="'background:' + getBgValueStats('possessiConcessiMetaDifensiva', item.optaId)">{{ item.possessiConcessiMetaDifensiva }}</td>				
				</tr>
				<tr>	
					<td><a href="" @click.prevent="setOrdering('possessiConcessiMetaOffensiva')">Possessi Individuali Concessi Metà Campo Offensiva</a> <span v-if="'possessiConcessiMetaOffensiva' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats.average.possessiConcessiMetaOffensiva }}</td>
					<td class="text-center" v-for="item in myStats" :style="'background:' + getBgValueStats('possessiConcessiMetaOffensiva', item.optaId)">{{ item.possessiConcessiMetaOffensiva }}</td>				
				</tr>
				<tr>	
					<td><a href="" @click.prevent="setOrdering('recuperiMetaDifensiva')">Palloni Recuperati in Metà Campo Difensiva</a> <span v-if="'recuperiMetaDifensiva' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats.average.recuperiMetaDifensiva }}</td>
					<td class="text-center" v-for="item in myStats" :style="'background:' + getBgValueStats('recuperiMetaDifensiva', item.optaId)">{{ item.recuperiMetaDifensiva }}</td>				
				</tr>
				<tr>	
					<td><a href="" @click.prevent="setOrdering('recuperiMetaOffensiva')">Palloni Recuperati in Metà Campo Offensiva</a> <span v-if="'recuperiMetaOffensiva' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats.average.recuperiMetaOffensiva }}</td>	
					<td class="text-center" v-for="item in myStats" :style="'background:' + getBgValueStats('recuperiMetaOffensiva', item.optaId)">{{ item.recuperiMetaOffensiva }}</td>				
				</tr>
				<tr>	
					<td><a href="" @click.prevent="setOrdering('progressivePassConcessi')">Progressive Pass Concessi</a> <span v-if="'progressivePassConcessi' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats.average.progressivePassConcessi }}</td>
					<td class="text-center" v-for="item in myStats" :style="'background:' + getBgValueStats('progressivePassConcessi', item.optaId)">{{ item.progressivePassConcessi }}</td>				
				</tr>
				<tr>	
					<td><a href="" @click.prevent="setOrdering('progressiveCarriesConcessi')">Progressive Carries Concessi</a> <span v-if="'progressiveCarriesConcessi' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats.average.progressiveCarriesConcessi }}</td>
					<td class="text-center" v-for="item in myStats" :style="'background:' + getBgValueStats('progressiveCarriesConcessi', item.optaId)">{{ item.progressiveCarriesConcessi }}</td>				
				</tr>
				<tr>	
					<td><a href="" @click.prevent="setOrdering('crossSubitiOP')">Cross Subiti (Open Play)</a> <span v-if="'crossSubitiOP' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats.average.crossSubitiOP }}</td>
					<td class="text-center" v-for="item in myStats" :style="'background:' + getBgValueStats('crossSubitiOP', item.optaId)">{{ item.crossSubitiOP }}</td>				
				</tr>
				<tr>	
					<td><a href="" @click.prevent="setOrdering('occasioniSubiteOP')">Occasioni Subite (Open Play)</a> <span v-if="'occasioniSubiteOP' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats.average.occasioniSubiteOP }}</td>
					<td class="text-center" v-for="item in myStats" :style="'background:' + getBgValueStats('occasioniSubiteOP', item.optaId)">{{ item.occasioniSubiteOP }}</td>				
				</tr>
				<tr>	
					<td><a href="" @click.prevent="setOrdering('occasioniSubiteSP')">Occasioni Subite (Set Pieces)</a> <span v-if="'occasioniSubiteSP' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats.average.occasioniSubiteSP }}</td>
					<td class="text-center" v-for="item in myStats" :style="'background:' + getBgValueStats('occasioniSubiteSP', item.optaId)">{{ item.occasioniSubiteSP }}</td>				
				</tr>
				<tr>	
					<td><a href="" @click.prevent="setOrdering('shotsSubitiDaCorner')">Shots Subiti da Corner</a> <span v-if="'shotsSubitiDaCorner' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats.average.shotsSubitiDaCorner }}</td>
					<td class="text-center" v-for="item in myStats" :style="'background:' + getBgValueStats('shotsSubitiDaCorner', item.optaId)">{{ item.shotsSubitiDaCorner }}</td>				
				</tr>
				<tr>	
					<td><a href="" @click.prevent="setOrdering('shotsSubitiDaOP')">Shots subiti da Open Play</a> <span v-if="'shotsSubitiDaOP' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats.average.shotsSubitiDaOP }}</td>
					<td class="text-center" v-for="item in myStats" :style="'background:' + getBgValueStats('shotsSubitiDaOP', item.optaId)">{{ item.shotsSubitiDaOP }}</td>				
				</tr>
			</tbody>
		</b-table-simple>
	</b-card>
</template>

<script>
import moment from 'moment'
export default {
	props: ['stats', 'team'],
	data: function() {
		return {
			ordering: { key: 'team', order: -1 },
		}
	},
	computed: {
		myStats: function () {
			if(this.stats && this.stats.teams) {
				if(this.ordering.key == 'team') {
					return _.sortBy(this.stats.teams, function(item) {
						if(item.optaId == this.team.opta.chiave) {
							return -1;
						} else {
							return 0;
						}
					}.bind(this));
				}
				return _.orderBy(this.stats.teams, [this.ordering.key],[this.ordering.order == -1 ? 'asc' : 'desc']);
			}
			return null;
		},
	},
	methods: {
		setOrdering: function(key) {
			if(this.ordering.key != key) {
				this.ordering.key = key;
				this.ordering.order = -1;
			} else {
				this.ordering.order *= -1;
			}
		},
		hsl_col_perc(percent, start, end) {
			if(percent > 100) {
				percent = 100;
			}
			var a = percent / 100,
			b = (end - start) * a,
			c = b + start;
			return 'hsl(' + c + ', 100%, 50%, 0.8)';
		},
		getBgValueStats(kpi, teamId) {
			var teamStat = this.stats.teams.find(item => item.optaId == teamId);
			var diffPerc = ((teamStat[kpi] - this.stats.average[kpi])/this.stats.average[kpi]) * 100;
			if(diffPerc >= 0) {
				return this.hsl_col_perc(diffPerc, 60, 0);
			} else {
				return this.hsl_col_perc(-diffPerc, 75, 135);
			}
		},
	}
}
</script>
