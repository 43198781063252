<template>
	<b-card title="Corners">
		<p>Corner a favore</p>
		<b-row>
			<b-col cols="8" class="pr-3">
				<b-table-simple small striped bordered responsive style="font-size:0.9rem">
					<thead>
						<tr>
							<th>Team</th>
							<th><a href="" @click.prevent="setOrdering('sorted_teams_1', 'crnrWon')">CrnrWon</a> <span v-if="'crnrWon' == orderings.sorted_teams_1.key">{{ orderings.sorted_teams_1.order == -1 ? '↥' : '↧' }}</span></th>
							<th><a href="" @click.prevent="setOrdering('sorted_teams_1', 'shotsAnalysisCorner')">Shots Analysis: Corner</a> <span v-if="'shotsAnalysisCorner' == orderings.sorted_teams_1.key">{{ orderings.sorted_teams_1.order == -1 ? '↥' : '↧' }}</span></th>
							<th><a href="" @click.prevent="setOrdering('sorted_teams_1', 'crnrShot')">CrnrShot</a> <span v-if="'crnrShot' == orderings.sorted_teams_1.key">{{ orderings.sorted_teams_1.order == -1 ? '↥' : '↧' }}</span></th>
							<th><a href="" @click.prevent="setOrdering('sorted_teams_1', 'goalCrnrs')">GoalCrnrs</a> <span v-if="'goalCrnrs' == orderings.sorted_teams_1.key">{{ orderings.sorted_teams_1.order == -1 ? '↥' : '↧' }}</span></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in sorted_teams_1">	
							<td :style="item.optaId == team.opta.chiave ? 'font-weight:bold;background:#00FFFF' : ''"><b-img :src="item.logo_url ? item.logo_url : item.logo" :height="20"></b-img> {{ item.name }}</td>
							<td :style="'background:' + getBgValueStats('crnrWon', item.optaId)">{{ item.crnrWon }}</td>	
							<td :style="'background:' + getBgValueStats('shotsAnalysisCorner', item.optaId)">{{ item.shotsAnalysisCorner }}</td>
							<td :style="'background:' + getBgValueStats('crnrShot', item.optaId)">{{ item.crnrShot }}</td>
							<td :style="'background:' + getBgValueStats('goalCrnrs', item.optaId)">{{ item.goalCrnrs }}</td>			
						</tr>
					</tbody>
				</b-table-simple>
			</b-col>
			<b-col class="pl-3">
				<b-table-simple small striped bordered responsive style="font-size:0.9rem">
					<thead>
						<tr>
							<th>Team</th>
							<th><a href="" @click.prevent="setOrdering('sorted_teams_2', 'crnrShotPerc')">%CornerShot</a> <span v-if="'crnrShotPerc' == orderings.sorted_teams_2.key">{{ orderings.sorted_teams_2.order == -1 ? '↥' : '↧' }}</span></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in sorted_teams_2">	
							<td :style="item.optaId == team.opta.chiave ? 'font-weight:bold;background:#00FFFF' : ''"><b-img :src="item.logo_url ? item.logo_url : item.logo" :height="20"></b-img> {{ item.name }}</td>
							<td :style="'background:' + getBgValueStats('crnrShotPerc', item.optaId)">{{ item.crnrShotPerc }}</td>	
						</tr>
					</tbody>
				</b-table-simple>
			</b-col>
		</b-row>
		<p>Corner contro</p>
		<b-row>
			<b-col cols="8" class="pr-3">
				<b-table-simple small striped bordered responsive style="font-size:0.9rem">
					<thead>
						<tr>
							<th>Team</th>
							<th><a href="" @click.prevent="setOrdering('sorted_teams_3', 'crnrWonOpp')">CrnrWon (opponent)</a> <span v-if="'crnrWonOpp' == orderings.sorted_teams_3.key">{{ orderings.sorted_teams_3.order == -1 ? '↥' : '↧' }}</span></th>
							<th><a href="" @click.prevent="setOrdering('sorted_teams_3', 'shotsAnalysisCornerOpp')">Shots Analysis: Corner (opponent)</a> <span v-if="'shotsAnalysisCornerOpp' == orderings.sorted_teams_3.key">{{ orderings.sorted_teams_3.order == -1 ? '↥' : '↧' }}</span></th>
							<th><a href="" @click.prevent="setOrdering('sorted_teams_3', 'crnrShotOpp')">CrnrShot (opponent)</a> <span v-if="'crnrShotOpp' == orderings.sorted_teams_3.key">{{ orderings.sorted_teams_3.order == -1 ? '↥' : '↧' }}</span></th>
							<th><a href="" @click.prevent="setOrdering('sorted_teams_3', 'goalCrnrsOpp')">GoalCrnrs (opponent)</a> <span v-if="'goalCrnrsOpp' == orderings.sorted_teams_3.key">{{ orderings.sorted_teams_3.order == -1 ? '↥' : '↧' }}</span></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in sorted_teams_3">	
							<td :style="item.optaId == team.opta.chiave ? 'font-weight:bold;background:#00FFFF' : ''"><b-img :src="item.logo_url ? item.logo_url : item.logo" :height="20"></b-img> {{ item.name }}</td>
							<td :style="'background:' + getBgValueStats('crnrWonOpp', item.optaId, true)">{{ item.crnrWonOpp }}</td>	
							<td :style="'background:' + getBgValueStats('shotsAnalysisCornerOpp', item.optaId, true)">{{ item.shotsAnalysisCornerOpp }}</td>
							<td :style="'background:' + getBgValueStats('crnrShotOpp', item.optaId, true)">{{ item.crnrShotOpp }}</td>
							<td :style="'background:' + getBgValueStats('goalCrnrsOpp', item.optaId, true)">{{ item.goalCrnrsOpp }}</td>			
						</tr>
					</tbody>
				</b-table-simple>
			</b-col>
			<b-col class="pl-3">
				<b-table-simple small striped bordered responsive style="font-size:0.9rem">
					<thead>
						<tr>
							<th>Team</th>
							<th><a href="" @click.prevent="setOrdering('sorted_teams_4', 'crnrShotOppPerc')">%CornerShot (opponent)</a> <span v-if="'crnrShotOppPerc' == orderings.sorted_teams_4.key">{{ orderings.sorted_teams_4.order == -1 ? '↥' : '↧' }}</span></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in sorted_teams_4" :class="item.optaId == team.opta.chiave ? 'bg-warning' : ''">	
							<td :style="item.optaId == team.opta.chiave ? 'font-weight:bold;background:#00FFFF' : ''"><b-img :src="item.logo_url ? item.logo_url : item.logo" :height="20"></b-img> {{ item.name }}</td>
							<td :style="'background:' + getBgValueStats('crnrShotOppPerc', item.optaId, true)">{{ item.crnrShotOppPerc }}</td>	
						</tr>
					</tbody>
				</b-table-simple>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
import moment from 'moment'
export default {
	props: ['stats', 'team'],
	data: function() {
		return {
			orderings: {
				sorted_teams_1: { key: 'crnrWon', order: 1 },
				sorted_teams_2: { key: 'crnrShotPerc', order: 1 },
				sorted_teams_3: { key: 'crnrWonOpp', order: 1 },
				sorted_teams_4: { key: 'crnrShotOppPerc', order: 1 },
			},

		}
	},
	computed: {
		teams: function() {
			return this.stats && this.stats.teams ? this.stats.teams : [];
		},
		sorted_teams_1: function() {
			return _.orderBy(this.teams, [this.orderings.sorted_teams_1.key],[this.orderings.sorted_teams_1.order == -1 ? 'asc' : 'desc']);
		},
		sorted_teams_2: function() {
			return _.orderBy(this.teams, [this.orderings.sorted_teams_2.key],[this.orderings.sorted_teams_2.order == -1 ? 'asc' : 'desc']);
		},
		sorted_teams_3: function() {
			return _.orderBy(this.teams, [this.orderings.sorted_teams_3.key],[this.orderings.sorted_teams_3.order == -1 ? 'asc' : 'desc']);
		},
		sorted_teams_4: function() {
			return _.orderBy(this.teams, [this.orderings.sorted_teams_4.key],[this.orderings.sorted_teams_4.order == -1 ? 'asc' : 'desc']);
		},
	},
	methods: {
		setOrdering: function(list, key) {
			if(this.orderings[list].key != key) {
				this.orderings[list].key = key;
				this.orderings[list].order = -1;
			} else {
				this.orderings[list].order *= -1;
			}
		},
		hsl_col_perc(percent, start, end) {
			if(percent > 100) {
				percent = 100;
			}
			var a = percent / 100,
			b = (end - start) * a,
			c = b + start;
			return 'hsl(' + c + ', 100%, 50%, 0.8)';
		},
		getBgValueStats(kpi, teamId, reverse = false) {
			var teamStat = this.stats.teams.find(item => item.optaId == teamId);
			var diffPerc = ((teamStat[kpi] - this.stats.average[kpi])/this.stats.average[kpi]) * 100;
			if(diffPerc >= 0) {
				if(reverse) 
					return this.hsl_col_perc(diffPerc, 60, 0);
				else
					return this.hsl_col_perc(diffPerc, 75, 135);
			} else {
				if(reverse) 
					return this.hsl_col_perc(-diffPerc, 75, 135);
				else
					return this.hsl_col_perc(-diffPerc, 60, 0);
			}
		},
	}
}
</script>
