<template>
	<b-card>
		<b-table-simple small striped bordered style="font-size:0.9rem">
			<thead>
				<tr>
					<th title="Numero"><a href="" @click.prevent="setOrdering('shirt')">Numero</a> <span v-if="'shirt' == ordering.key">{{ ordering.order == -1 ? '↥' : '↧' }}</span></th>
					<th title="Player"><a href="" @click.prevent="setOrdering('player')">Player</a> <span v-if="'player' == ordering.key">{{ ordering.order == -1 ? '↥' : '↧' }}</span></th>
					<th title="Altezza"><a href="" @click.prevent="setOrdering('height')">Altezza</a> <span v-if="'height' == ordering.key">{{ ordering.order == -1 ? '↥' : '↧' }}</span></th>
					<th title="Piede"><a href="" @click.prevent="setOrdering('foot')">Piede</a> <span v-if="'foot' == ordering.key">{{ ordering.order == -1 ? '↥' : '↧' }}</span></th>
					<th title="Data di nascita"><a href="" @click.prevent="setOrdering('birthDate')">Data di nascita</a> <span v-if="'birthDate' == ordering.key">{{ ordering.order == -1 ? '↥' : '↧' }}</span></th>
					<th title="Minutes"><a href="" @click.prevent="setOrdering('minutes')">Minutes</a> <span v-if="'minutes' == ordering.key">{{ ordering.order == -1 ? '↥' : '↧' }}</span></th>
					<th title="Goals"><a href="" @click.prevent="setOrdering('goals')">Goals</a> <span v-if="'goals' == ordering.key">{{ ordering.order == -1 ? '↥' : '↧' }}</span></th>
					<th title="Ruolo di reparto"><a href="" @click.prevent="setOrdering('genericPosition')">Ruolo di reparto</a> <span v-if="'genericPosition' == ordering.key">{{ ordering.order == -1 ? '↥' : '↧' }}</span></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="player in thePlayers">
					<td>{{ player.shirt }}</td>
					<td>{{ player.firstName + ' ' + player.lastName }}</td>
					<td>{{ player.height }}</td>
					<td>{{ player.foot | formatPiede }}</td>
					<td>{{ player.birthDate | formatDate }}</td>
					<td>{{ player.minutes }}</td>
					<td>{{ player.goals }}</td>
					<td>{{ player.genericPosition }}</td>
				</tr>
			</tbody>
		</b-table-simple>
	</b-card>
</template>

<script>
import moment from 'moment'
export default {
	props: ['players'],
	data: function() {
		return {
			ordering: { key: 'genericPosition', order: -1 },
		}
	},
	computed: {
		thePlayers: function() {
			if(this.ordering.key == 'genericPosition') {
				if(this.ordering.order == -1) {
					return _.cloneDeep(this.players);
				} else {
					return _.reverse(_.cloneDeep(this.players));
				}
			}
			return _.orderBy(this.players, function(a) {
				var value = a[this.ordering.key];
				if(this.ordering.key == 'height' || this.ordering.key == 'shirt') {
					if(value == null) {
						value = 0;
					}
					value = parseInt(value);
				}
				if(value == '-') {
					value = 0;
				}
				return value;
			}.bind(this), [ this.ordering.order == -1 ? 'asc' : 'desc' ]);
		}
	},
	methods: {
		setOrdering: function(key) {
			if(this.ordering.key != key) {
				this.ordering.key = key;
				this.ordering.order = -1;
			} else {
				this.ordering.order *= -1;
			}
		},
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY') + ' ('+ moment().diff(date, 'years') +')'; 
			}
			return "";
		},
		formatPiede: function (foot) {
			var piede = '';
			if(foot == 'right') {
				piede = 'DESTRO';
			} else if(foot == 'left') {
				piede = 'SINISTRO';
			} else if(foot == 'both') {
				piede = 'AMBIDESTRO';
			} 
			return piede;
		}
	},
}
</script>
