<template>
	<b-card title="team stats">
		<b-row v-if="myStats && myGoals">
			<b-col>
				<b-card no-body class="p-2 mb-2">
					<b-row><b-col class="text-center">
						<div>Possesso palla</div>
						<!--b-table-simple small striped bordered style="font-size:0.9rem">
							<thead>
								<tr>
									<th>{{ team.name }}</th>
									<th>Media campionato</th>
								</tr>
							</thead>
							<tbody>
								<tr style="font-size:1.5rem">
									<td>{{ myStats.possessoPerc }} %</td>
									<td>{{stats.average.possessoPerc }} %</td>	
								</tr>
							</tbody>
						</b-table-simple-->
						<div :style="'font-size:1.5rem;border-radius:50%;height:120px;width:120px;margin:auto;border:2px solid #666666;line-height: 120px;font-weight:bold;background:' + getBgValueStats('possessoPerc')">{{ myStats.possessoPerc }} %</div>
					</b-col>
					<b-col cols="8"><div class="text-center">Possessi nei terzi</div>
						<b-table-simple small striped bordered style="font-size:0.9rem">
							<thead>
								<tr>
									<th></th>
									<th>{{ team.name }}</th>
									<th>Media campionato</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Tocchi difesa</td>
									<td :style="'background:' + getBgValueStats('tocchiDifesa')">{{ myStats.tocchiDifesa }}</td>
									<td>{{stats.average.tocchiDifesa }}</td>	
								</tr>
								<tr>
									<td>Tocchi centrocampo</td>
									<td :style="'background:' + getBgValueStats('tocchiCentrocampo')">{{ myStats.tocchiCentrocampo }}</td>
									<td>{{stats.average.tocchiCentrocampo }}</td>	
								</tr>
								<tr>
									<td>Tocchi attacco</td>
									<td :style="'background:' + getBgValueStats('tocchiAttacco')">{{ myStats.tocchiAttacco }}</td>
									<td>{{stats.average.tocchiAttacco }}</td>	
								</tr>
							</tbody>
						</b-table-simple>
					</b-col></b-row>
				</b-card>
				<b-card no-body class="p-2 mb-2">
					<b-row>
						<b-col cols="12">
							<div class="text-center">Stile offensivo (Avg match)</div>
							<b-table-simple small striped bordered style="font-size:0.9rem">
								<thead>
									<tr>
										<th></th>
										<th>{{ team.name }}</th>
										<th>Media campionato</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Passaggi</td>
										<td :style="'background:' + getBgValueStats('passaggi')">{{ myStats.passaggi }}</td>
										<td>{{stats.average.passaggi }}</td>	
									</tr>
									<tr>
										<td>Lanci lunghi</td>
										<td :style="'background:' + getBgValueStats('lanciLunghi')">{{ myStats.lanciLunghi }}</td>
										<td>{{stats.average.lanciLunghi }}</td>	
									</tr>
									<tr>
										<td>Passaggi verticali</td>
										<td :style="'background:' + getBgValueStats('passaggiVerticali')">{{ myStats.passaggiVerticali }}</td>
										<td>{{stats.average.passaggiVerticali }}</td>	
									</tr>
									<tr>
										<td>Cross</td>
										<td :style="'background:' + getBgValueStats('crosses')">{{ myStats.crosses }}</td>
										<td>{{stats.average.crosses }}</td>	
									</tr>
									<tr>
										<td>Filtranti</td>
										<td :style="'background:' + getBgValueStats('filtranti')">{{ myStats.filtranti }}</td>
										<td>{{stats.average.filtranti }}</td>	
									</tr>
									<tr>
										<td>Dribbling</td>
										<td :style="'background:' + getBgValueStats('dribbling')">{{ myStats.dribbling }}</td>
										<td>{{stats.average.dribbling }}</td>	
									</tr>
									<tr>
										<td>Contropiede: tiri</td>
										<td :style="'background:' + getBgValueStats('tiriDaContropiede')">{{ myStats.tiriDaContropiede }}</td>
										<td>{{stats.average.tiriDaContropiede }}</td>	
									</tr>
								</tbody>
							</b-table-simple>
						</b-col>
						<b-col cols="12">
							<div class="text-center">Stile difensivo (Avg match)</div>
							<b-table-simple small striped bordered style="font-size:0.9rem">
								<thead>
									<tr>
										<th></th>
										<th>{{ team.name }}</th>
										<th>Media campionato</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Pressioni individuali metà campo offensiva</td>
										<td :style="'background:' + getBgValueStats('pressioniMetaOffensiva')">{{ myStats.pressioniMetaOffensiva }}</td>
										<td>{{stats.average.pressioniMetaOffensiva }}</td>	
									</tr>
									<tr>
										<td>Recuperi palla metà campo offensiva</td>
										<td :style="'background:' + getBgValueStats('recuperiMetaOffensiva')">{{ myStats.recuperiMetaOffensiva }}</td>
										<td>{{stats.average.recuperiMetaOffensiva }}</td>	
									</tr>
									<tr>
										<td>Pressioni individuali metà campo difensiva</td>
										<td :style="'background:' + getBgValueStats('pressioniMetaDifensiva')">{{ myStats.pressioniMetaDifensiva }}</td>
										<td>{{stats.average.pressioniMetaDifensiva }}</td>	
									</tr>
									<tr>
										<td>Recuperi palla metà campo difensiva</td>
										<td :style="'background:' + getBgValueStats('recuperiMetaDifensiva')">{{ myStats.recuperiMetaDifensiva }}</td>
										<td>{{stats.average.recuperiMetaDifensiva }}</td>	
									</tr>
								</tbody>
							</b-table-simple>
						</b-col>
					</b-row>
				</b-card>
			</b-col>
			<b-col>
				<b-card no-body class="p-2 mb-2">
					<b-row><b-col>
						<b-table-simple small striped bordered style="font-size:0.9rem">
							<thead>
								<tr>
									<th width="20%"></th>
									<th>Goals in gioco libero</th>
									<th>Goals in contropiede</th>
									<th>Goals su inattiva indiretta</th>
									<th>Goals su punizione diretta</th>
									<th>Goals su rigore</th>
									<th>Totale complessivo</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Goal fatti</td>
									<td :style="'background:' + getBgValueGoals('goalsFattiGiocoLibero')">{{ myGoals.goalsFattiGiocoLibero }}</td>
									<td :style="'background:' + getBgValueGoals('goalsFattiContropiede')">{{ myGoals.goalsFattiContropiede }}</td>
									<td :style="'background:' + getBgValueGoals('goalsFattiInattivaIndiretta')">{{ myGoals.goalsFattiInattivaIndiretta }}</td>
									<td :style="'background:' + getBgValueGoals('goalsFattiPunizioneDiretta')">{{ myGoals.goalsFattiPunizioneDiretta }}</td>
									<td :style="'background:' + getBgValueGoals('goalsFattiRigole')">{{ myGoals.goalsFattiRigole }}</td>
									<td>{{ myGoals.goalsFatti }}</td>
								</tr>
								<tr>
									<td>Media campionato</td>
									<td>{{ goals.average.goalsFattiGiocoLibero }}</td>
									<td>{{ goals.average.goalsFattiContropiede }}</td>
									<td>{{ goals.average.goalsFattiInattivaIndiretta }}</td>
									<td>{{ goals.average.goalsFattiPunizioneDiretta }}</td>
									<td>{{ goals.average.goalsFattiRigole }}</td>
									<td>{{ goals.average.goalsFatti }}</td>
								</tr>
							</tbody>
						</b-table-simple>
					</b-col></b-row>
					<b-row><b-col>
						<b-table-simple small striped bordered style="font-size:0.9rem">
							<thead>
								<tr>
									<th width="20%"></th>
									<th>Goals in gioco libero</th>
									<th>Goals in contropiede</th>
									<th>Goals su inattiva indiretta</th>
									<th>Goals su punizione diretta</th>
									<th>Goals su rigore</th>
									<th>Totale complessivo</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Goal subiti</td>
									<td :style="'background:' + getBgValueGoals('goalsSubitiGiocoLibero', true)">{{ myGoals.goalsSubitiGiocoLibero }}</td>
									<td :style="'background:' + getBgValueGoals('goalsSubitiContropiede', true)">{{ myGoals.goalsSubitiContropiede }}</td>
									<td :style="'background:' + getBgValueGoals('goalsSubitiInattivaIndiretta', true)">{{ myGoals.goalsSubitiInattivaIndiretta }}</td>
									<td :style="'background:' + getBgValueGoals('goalsSubitiPunizioneDiretta', true)">{{ myGoals.goalsSubitiPunizioneDiretta }}</td>
									<td :style="'background:' + getBgValueGoals('goalsSubitiRigole', true)">{{ myGoals.goalsSubitiRigole }}</td>
									<td>{{ myGoals.goalsSubiti }}</td>
								</tr>
								<tr>
									<td>Media campionato</td>
									<td>{{ goals.average.goalsSubitiGiocoLibero }}</td>
									<td>{{ goals.average.goalsSubitiContropiede }}</td>
									<td>{{ goals.average.goalsSubitiInattivaIndiretta }}</td>
									<td>{{ goals.average.goalsSubitiPunizioneDiretta }}</td>
									<td>{{ goals.average.goalsSubitiRigole }}</td>
									<td>{{ goals.average.goalsSubiti }}</td>
								</tr>
							</tbody>
						</b-table-simple>
					</b-col></b-row>
					<b-row><b-col><div class="text-center">Produzione offensiva</div><b-table-simple small striped bordered style="font-size:0.9rem">
						<thead>
							<tr>
								<th>XG prodotti in gioco libero</th>
								<th>XG prodotti su inattiva indiretta</th>
								<th>Totale complessivo</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td :style="'background:' + getBgValueGoals('xGFattiGiocoLibero')">{{ myGoals.xGFattiGiocoLibero }}</td>
								<td :style="'background:' + getBgValueGoals('xGFattiInattivaIndiretta')">{{ myGoals.xGFattiInattivaIndiretta }}</td>
								<td>{{ Math.round((myGoals.xGFattiGiocoLibero + myGoals.xGFattiInattivaIndiretta) * 100) / 100 }}</td>
							</tr>
							<tr>
								<td>{{ goals.average.xGFattiGiocoLibero }}</td>
								<td>{{ goals.average.xGFattiInattivaIndiretta }}</td>
								<td>{{ Math.round((goals.average.xGFattiGiocoLibero + goals.average.xGFattiInattivaIndiretta) * 100) / 100 }}</td>
							</tr>
						</tbody>
					</b-table-simple></b-col><b-col><div class="text-center">Produzione difensiva</div><b-table-simple small striped bordered style="font-size:0.9rem">
						<thead>
							<tr>
								<th>XG concessi in gioco libero</th>
								<th>XG concessi su inattiva indiretta</th>
								<th>Totale complessivo</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td :style="'background:' + getBgValueGoals('xGSubitiGiocoLibero', true)">{{ myGoals.xGSubitiGiocoLibero }}</td>
								<td :style="'background:' + getBgValueGoals('xGSubitiInattivaIndiretta', true)">{{ myGoals.xGSubitiInattivaIndiretta }}</td>
								<td>{{ Math.round((myGoals.xGSubitiGiocoLibero + myGoals.xGSubitiInattivaIndiretta) * 100) / 100 }}</td>
							</tr>
							<tr>
								<td>{{ goals.average.xGSubitiGiocoLibero }}</td>
								<td>{{ goals.average.xGSubitiInattivaIndiretta }}</td>
								<td>{{ Math.round((goals.average.xGSubitiGiocoLibero + goals.average.xGSubitiInattivaIndiretta) * 100) / 100 }}</td>
							</tr>
						</tbody>
					</b-table-simple></b-col></b-row>
					<b-row><b-col><b-card no-body class="p-2">
						<b-table-simple small striped bordered style="font-size:0.9rem">
							<thead>
								<tr>
									<th rowspan="2">Modulo Avversario</th>
									<th :colspan="rows.length" class="text-center">Modulo adottato</th>
								</tr>
								<tr>
									<th v-for="col in columns" class="text-center">{{ col.split('^')[0] }}</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="row in rows">
									<td style="vertical-align: middle;">{{ row.split('^')[0] }}</td>
									<td v-for="col in columns" class="text-center">
										<div v-if="getCellValue(row, col)">{{ getCellValue(row, col).minutes }} min.<br/>{{ getCellValue(row, col).perc }}%</div>
									</td>
								</tr>
							</tbody>
						</b-table-simple>
					</b-card></b-col></b-row>
				</b-card>
			</b-col>	
		</b-row>
	</b-card>
</template>

<script>
export default {
	props: ['team', 'stats', 'goals', 'formations'],
	data: function() {
		return {

		}
	},
	methods: {
		getCellValue(row, col) {
			var mod = this.moduli.find(item => item.key == col);
			if(mod.opponent) {
				var opp = mod.opponent.find(item2 => item2.key == row);
				if(opp) {
					var cell = {
						minutes: opp.minutes,
						perc: Math.round((opp.minutes/this.getTotMinByModOpp(row) * 100) * 10) / 10,
					}
					return cell;
				}
			}
			return null;
		},
		getTotMinByModOpp(mod) {
			var min = 0;
			this.moduli.forEach(item => {
				if(item.opponent) {
					item.opponent.forEach(opp => {
						if(opp.key == mod) {
							min += opp.minutes;
						}
					});
				}
			});
			return min;
		},
		hsl_col_perc(percent, start, end) {
			if(percent > 100) {
				percent = 100;
			}
			var a = percent / 100,
			b = (end - start) * a,
			c = b + start;
			return 'hsl(' + c + ', 100%, 50%, 0.8)';
		},
		getBgValueStats(kpi, reverse = false) {
			var diffPerc = ((this.myStats[kpi] - this.stats.average[kpi])/this.stats.average[kpi]) * 100;
			if(diffPerc >= 0) {
				if(reverse) 
					return this.hsl_col_perc(diffPerc, 60, 0);
				else
					return this.hsl_col_perc(diffPerc, 75, 135);
			} else {
				if(reverse) 
					return this.hsl_col_perc(-diffPerc, 75, 135);
				else
				 	return this.hsl_col_perc(-diffPerc, 60, 0);
			}
		},
		getBgValueGoals(kpi, reverse = false) {
			var diffPerc = ((this.myGoals[kpi] - this.goals.average[kpi])/this.goals.average[kpi]) * 100;
			if(diffPerc >= 0) {
				if(reverse) 
					return this.hsl_col_perc(diffPerc, 60, 0);
				else
					return this.hsl_col_perc(diffPerc, 75, 135);
			} else {
				if(reverse) 
					return this.hsl_col_perc(-diffPerc, 75, 135);
				else
				 	return this.hsl_col_perc(-diffPerc, 60, 0);
			}
		}
	},
	computed: {
		myStats: function () {
			if(this.stats && this.stats.teams) 
				return this.stats.teams.find(item => item.optaId == this.team.opta.chiave);
			return null;
		},
		myGoals: function () {
			if(this.goals && this.goals.teams) 
				return this.goals.teams.find(item => item.optaId == this.team.opta.chiave);
			return null;
		},
		moduli: function() {
			return this.formations && this.formations.formations ? _.values(this.formations.formations) : [];
		},
		columns: function() {
			return this.moduli.map(item => item.key);
		},
		rows: function() {
			var rows = [];
			this.moduli.forEach(mod => {
				if(mod.opponent) {
					mod.opponent.forEach(opp => {
						if(!rows.includes(opp.key)) {
							rows.push(opp.key);
						}
					});
				}
			});
			return rows;
		}
	},
}
</script>
