<template>
	<b-card title="Report Skills Offensive">
		<b-table-simple small striped bordered responsive v-if="myStats" style="font-size:0.9rem">
			<thead>
				<tr>
					<th style="min-width:200px;"><a href="" @click.prevent="setOrdering('team')">Team</a></th>
					<th class="text-center">AVG</th>
					<th class="text-center" v-for="item in myStats" :style="item.optaId == team.opta.chiave ? 'background:#00FFFF' : ''"><b-img :src="item.logo_url ? item.logo_url : item.logo" :height="30"></b-img><div>{{ item.name }}</div></th>
				</tr>
			</thead>
			<tbody>
				<tr>	
					<td><a href="" @click.prevent="setOrdering('falliSubiti')">Falli subiti</a> <span v-if="'falliSubiti' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats.average.falliSubiti }}</td>
					<td class="text-center" v-for="item in myStats" :style="'background:' + getBgValueStats('falliSubiti', item.optaId)">{{ item.falliSubiti }}</td>				
				</tr>
				<tr>	
					<td><a href="" @click.prevent="setOrdering('possessiMetaDifensiva')">Possessi individuali metà campo difensiva</a> <span v-if="'possessiMetaDifensiva' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats.average.possessiMetaDifensiva }}</td>
					<td class="text-center" v-for="item in myStats" :style="'background:' + getBgValueStats('possessiMetaDifensiva', item.optaId)">{{ item.possessiMetaDifensiva }}</td>				
				</tr>
				<tr>	
					<td><a href="" @click.prevent="setOrdering('possessiMetaOffensiva')">Possessi individuali metà campo offensiva</a> <span v-if="'possessiMetaOffensiva' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats.average.possessiMetaOffensiva }}</td>
					<td class="text-center" v-for="item in myStats" :style="'background:' + getBgValueStats('possessiMetaOffensiva', item.optaId)">{{ item.possessiMetaOffensiva }}</td>				
				</tr>
				<tr>	
					<td><a href="" @click.prevent="setOrdering('possessiAreaAvversaria')">Possessi individuali in area avversaria</a> <span v-if="'possessiAreaAvversaria' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats.average.possessiAreaAvversaria }}</td>	
					<td class="text-center" v-for="item in myStats" :style="'background:' + getBgValueStats('possessiAreaAvversaria', item.optaId)">{{ item.possessiAreaAvversaria }}</td>				
				</tr>
				<tr>	
					<td><a href="" @click.prevent="setOrdering('progressiveCarries')">Progressive carries</a> <span v-if="'progressiveCarries' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats.average.progressiveCarries }}</td>
					<td class="text-center" v-for="item in myStats" :style="'background:' + getBgValueStats('progressiveCarries', item.optaId)">{{ item.progressiveCarries }}</td>				
				</tr>
				<tr>	
					<td><a href="" @click.prevent="setOrdering('attacchiDiretti3D')">Attacchi Diretti da 3Dif Tentati</a> <span v-if="'attacchiDiretti3D' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats.average.attacchiDiretti3D }}</td>
					<td class="text-center" v-for="item in myStats" :style="'background:' + getBgValueStats('attacchiDiretti3D', item.optaId)">{{ item.attacchiDiretti3D }}</td>				
				</tr>
				<tr>	
					<td><a href="" @click.prevent="setOrdering('passaggiLunghi')">Passaggi Lunghi</a> <span v-if="'passaggiLunghi' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats.average.passaggiLunghi }}</td>	
					<td class="text-center" v-for="item in myStats" :style="'background:' + getBgValueStats('passaggiLunghi', item.optaId)">{{ item.passaggiLunghi }}</td>				
				</tr>
				<tr>	
					<td><a href="" @click.prevent="setOrdering('crossFondoCmp')">Cross dal Fondo Completati (OP)</a> <span v-if="'crossFondoCmp' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats.average.crossFondoCmp }}</td>
					<td class="text-center" v-for="item in myStats" :style="'background:' + getBgValueStats('crossFondoCmp', item.optaId)">{{ item.crossFondoCmp }}</td>				
				</tr>
				<tr>	
					<td><a href="" @click.prevent="setOrdering('crossFondoAtt')">Cross dal Fondo Tentati (OP)</a> <span v-if="'crossFondoAtt' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats.average.crossFondoAtt }}</td>
					<td class="text-center" v-for="item in myStats" :style="'background:' + getBgValueStats('crossFondoAtt', item.optaId)">{{ item.crossFondoAtt }}</td>				
				</tr>
				<tr>	
					<td><a href="" @click.prevent="setOrdering('crossCmp')">Cross Completati (Open Play)</a> <span v-if="'crossCmp' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats.average.crossCmp }}</td>	
					<td class="text-center" v-for="item in myStats" :style="'background:' + getBgValueStats('crossCmp', item.optaId)">{{ item.crossCmp }}</td>				
				</tr>
				<tr>	
					<td><a href="" @click.prevent="setOrdering('crossAtt')">Cross Tentati (Open Play)</a> <span v-if="'crossAtt' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats.average.crossAtt }}</td>
					<td class="text-center" v-for="item in myStats" :style="'background:' + getBgValueStats('crossAtt', item.optaId)">{{ item.crossAtt }}</td>				
				</tr>
				<tr>	
					<td><a href="" @click.prevent="setOrdering('dribbling')">1v1</a> <span v-if="'dribbling' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats.average.dribbling }}</td>
					<td class="text-center" v-for="item in myStats" :style="'background:' + getBgValueStats('dribbling', item.optaId)">{{ item.dribbling }}</td>				
				</tr>
				<tr>	
					<td><a href="" @click.prevent="setOrdering('dribblingSucc')">1v1 riusciti</a> <span v-if="'dribblingSucc' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats.average.dribblingSucc }}</td>
					<td class="text-center" v-for="item in myStats" :style="'background:' + getBgValueStats('dribblingSucc', item.optaId)">{{ item.dribblingSucc }}</td>				
				</tr>
				<tr>	
					<td><a href="" @click.prevent="setOrdering('shtInBoxOP')">Shots from Inside Box</a> <span v-if="'shtInBoxOP' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats.average.shtInBoxOP }}</td>	
					<td class="text-center" v-for="item in myStats" :style="'background:' + getBgValueStats('shtInBoxOP', item.optaId)">{{ item.shtInBoxOP }}</td>				
				</tr>
				<tr>	
					<td><a href="" @click.prevent="setOrdering('occasioniOP')">Occasioni (Open Play)</a> <span v-if="'occasioniOP' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats.average.occasioniOP }}</td>
					<td class="text-center" v-for="item in myStats" :style="'background:' + getBgValueStats('occasioniOP', item.optaId)">{{ item.occasioniOP }}</td>				
				</tr>
				<tr>	
					<td><a href="" @click.prevent="setOrdering('occasioniSP')">Occasioni (Set Pieces)</a> <span v-if="'occasioniSP' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats.average.occasioniSP }}</td>
					<td class="text-center" v-for="item in myStats" :style="'background:' + getBgValueStats('occasioniSP', item.optaId)">{{ item.occasioniSP }}</td>				
				</tr>
			</tbody>
		</b-table-simple>
	</b-card>
</template>

<script>
import moment from 'moment'
export default {
	props: ['stats', 'team'],
	data: function() {
		return {
			ordering: { key: 'team', order: -1 },
		}
	},
	computed: {
		myStats: function () {
			if(this.stats && this.stats.teams) {
				if(this.ordering.key == 'team') {
					return _.sortBy(this.stats.teams, function(item) {
						if(item.optaId == this.team.opta.chiave) {
							return -1;
						} else {
							return 0;
						}
					}.bind(this));
				}
				return _.orderBy(this.stats.teams, [this.ordering.key],[this.ordering.order == -1 ? 'asc' : 'desc']);
			}
			return null;
		},
	},
	methods: {
		setOrdering: function(key) {
			if(this.ordering.key != key) {
				this.ordering.key = key;
				this.ordering.order = -1;
			} else {
				this.ordering.order *= -1;
			}
		},
		hsl_col_perc(percent, start, end) {
			if(percent > 100) {
				percent = 100;
			}
			var a = percent / 100,
			b = (end - start) * a,
			c = b + start;
			return 'hsl(' + c + ', 100%, 50%, 0.8)';
		},
		getBgValueStats(kpi, teamId) {
			var teamStat = this.stats.teams.find(item => item.optaId == teamId);
			var diffPerc = ((teamStat[kpi] - this.stats.average[kpi])/this.stats.average[kpi]) * 100;
			if(diffPerc >= 0) {
				return this.hsl_col_perc(diffPerc, 75, 135);
			} else {
				return this.hsl_col_perc(-diffPerc, 60, 0);
			}
		},
	}
}
</script>
