<template>
	<b-card title="Rosa - Principali">
		<b-row style="font-size:0.9rem">
			<b-col cols="2" v-for="player in players" class="pb-2">
				<b-card class="h-100" no-body>
					<div class="bg-warning p-1 font-weight-bold">{{ getTitle(player) }}</div>
					<b-row class="p-1">
						<b-col cols="8" class="p-2">
							<div style="height:40%">{{ player.position }}</div>
							<b-row class="text-center border-top border-bottom"><b-col class="border-right">{{ player.height }}</b-col><b-col>{{ player.foot | formatPiede }}</b-col></b-row>
							<b-row class="text-center border-bottom"><b-col>{{ player.birthDate | formatDate }}</b-col></b-row>
							<b-row class="text-center"><b-col>{{ player.birthCountry }}</b-col></b-row>
						</b-col>
						<b-col cols="4" class="text-center p-2"><b-img fluid :src="player.avatar_url ? player.avatar_url : player.avatar"></b-img></b-col>
					</b-row>
				</b-card>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
import moment from 'moment'
export default {
	props: ['players'],
	data: function() {
		return {

		}
	},
	components: {
	},
	methods: {
		getTitle(player) {
			return player.shirt + '. ' + player.firstName + ' ' + player.lastName; 
		}
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY') + ' ('+ moment().diff(date, 'years') +')'; 
			}
			return "";
		},
		formatPiede: function (foot) {
			var piede = '';
			if(foot == 'right') {
				piede = 'DESTRO';
			} else if(foot == 'left') {
				piede = 'SINISTRO';
			} else if(foot == 'both') {
				piede = 'AMBIDESTRO';
			} 
			return piede;
		}
	},
}
</script>
