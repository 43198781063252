<template>
	<div class="single-data-card card shadow p-2">
        <label class="mb-0">{{ label }}</label>
        <div class="data-content">
            {{data}}
        </div>
    </div>
</template>

<style scoped>

.data-content{
	max-height: 7rem;
	white-space: pre-line;
	font-weight: bold;
	overflow-y: auto;
	overflow-x: hidden;
}
</style>

<script>

import _ from 'lodash'
var Auth = require('@/auth.js');

export default {
	props: [ 'label', 'data' ],
	data: function() {
		return {}
	},
    computed: {
		auth: function() {
			return Auth;
		},

		_: function() {
			return _;
		}
	},
}

</script>
