<template id="dashboard">
	<section class="dashboard">

		<headapp></headapp>

		<div class="container-fluid m-y-1">

			<menusub></menusub>

			<div class="card">
				<div class="card-body">
					<b-row>
						<b-col cols="3">
							<div>
								
								<div class="mb-3">
									<label class="color-rosso font-bold text-uppercase">Intervallo di Ricerca</label>
									<div class="form-row">
										<div class="form-group col-sm-12 mb-1">
											<b-list-group-item class="p-2">
												<b-form-datepicker placeholder="Dal ..." class="mb-2" size="sm" v-model="from"></b-form-datepicker>
												<b-form-datepicker placeholder="Al ..." size="sm" v-model="to"></b-form-datepicker>
											</b-list-group-item>
										</div>
									</div>
									
									<div>
										<label class="color-rosso font-bold text-uppercase my-3">Tipologia di esercizio</label>
										<div class="form-row">
											<div class="form-group col-sm-12 mb-1 exercise-drills-container">
												<div class="text-center" v-if="loadingDrills">
													<b-spinner class="drills-loading" label="loading..."></b-spinner>
												</div>
												<b-list-group-item v-else v-for="drill in drills" class="p-2">
													<b-checkbox v-model="drills_selected" :value="drill.id">{{ drill.name }}</b-checkbox>
												</b-list-group-item>
											</div>
										</div>
									</div>
								</div>

								<b-button class="mb-2 btn-sm" 
									block 
									@click.prevent="doSearch" 
									:disabled="drills_selected.length == 0 || from == null || to == null || loading"
								>
									Cerca
								</b-button>
							</div>

						</b-col>
						<b-col>
							<label class="color-rosso font-bold text-uppercase">Esercizi</label>

							<div class="text-center" v-if="loading">
								<b-spinner class="excercise-loading" label="loading..."></b-spinner>
							</div>
							
							<div v-else-if="results">
								<b-table-simple>
									<b-thead class="text-center">
										<th>LUN</th>
										<th>MAR</th>
										<th>MER</th>
										<th>GIO</th>
										<th>VEN</th>
										<th>SAB</th>
									</b-thead>
									<b-tbody>
										<tr v-for="week in calendar.out_days">
											<td v-for="i in 6" style="width: 16.6%;">
												<div v-if="week[i]">
													<div class="text-muted">{{week[i][0].date}}</div>
													<template v-for="groupedFasi in week[i][0].data">
														<div 
															class="fase-item" 
															v-for="fase in groupedFasi.fasi"
															@click="openFasePreview(fase, groupedFasi.date)"
														>
															{{ fase.nome }} - {{ fase.persone.length }} giocatori
														</div>
													</template>
												</div>
											</td>
										</tr>
									</b-tbody>
								</b-table-simple>
							</div>

							<h3 v-else class="text-center text-muted mt-5 p-4">Seleziona un <strong>intervallo di ricerca</strong> e almeno una <strong>tipologia d'esercizio</strong> per visualizzare i vecchi eventi</h3>
						</b-col>
					</b-row>
				</div>
			</div>

		</div>

		<FaseEsercizioModal
			v-if="showModal"
			@close="showModal = false"
			:fase="faseSelected"
			:date="dateSelected"
		>
		</FaseEsercizioModal>

	<footerapp></footerapp>

</section>
</template>

<style scoped>
.exercise-drills-container{
	height: 52vh;
	overflow-y: scroll;
	overflow-x: hidden;
}

.fase-item{
	cursor: pointer;
	font-size: 11px;
	background-color: #8E1F2F;
	color: white;
	margin-bottom: 3px;
	padding: 3px;
    border-radius: 3px;
}

.fase-item:nth-child(odd){
	background-color: #F0BC42;
	color: black;
}

.excercise-loading{
	top: 35vh; 
	position: absolute; 
	width: 3rem; 
	height: 3rem; 
	border: 0.3em solid currentColor;
	border-right-color: transparent;
}

.drills-loading{
	width: 1.8rem; 
	height: 1.8rem; 
	border: 0.2em solid currentColor;
	border-right-color: transparent;
}
</style>

<script>
var Auth = require('../../../auth.js');
import moment from 'moment';
export default {

	components: {
		headapp:            require('../../layout/headapp.vue').default,
		menusub:            require('../../layout/menusub.vue').default,
		footerapp:          require('../../layout/footerapp.vue').default,
		FaseEsercizioModal: require('@/components/layout/Sessioni/FaseEsercizioModal.vue').default,
	},

	data: function(){
		return {
			results: null,
			drills: null,
			loading: false,
			loadingDrills: false,
			drills_selected: [],
			from: null,
			to: null,
			showModal: false,
			faseSelected: null,
			dateSelected: null,
		}
	},
	created: function() {
		this.fetchDrills();
	},
	methods: {
		doSearch: function() {
			this.loading = true
			this.$http.post(this.$store.state.apiEndPoint + '/exercise/drills/search', { drills: this.drills_selected, from: this.from, to: this.to, category_id: this.$store.state.categoriaId }).then(function(data) {
				this.results = data.data;
				this.loading = false
			})
		},
		fetchDrills: function() {
			this.loadingDrills = true
			this.$http.get(this.$store.state.apiEndPoint + '/exercise/drills').then(function(data) {
				this.drills = data.data;
				this.loadingDrills = false
			})
		},
		openFasePreview: function( fase, date ) {
			const dateFormatted = moment(date).format('DD/MM/YYYY HH:mm');

			this.faseSelected = fase			
			this.dateSelected = dateFormatted
			this.showModal    = true			
		}
	},
	computed: {
		calendar: function() {

			var days = _.map(this.results.calendar, function(e) {
				return moment(e.date).format('X');
			});

			var cal = _.groupBy(this.results.calendar, function(e) {
				return moment(e.date).format('YYYY-MM-DD');
			});

			var i = 0;
			var go = true;
			var out_days = [];
			var base = moment.unix(_.min(days));
			var end = moment.unix(_.max(days));

			while(base.isBefore(end)) {

				out_days.push({
					weekDay: base.weekday(),
					week: base.week(),
					date: base.format('YYYY-MM-DD'),
					data: cal[base.format('YYYY-MM-DD')]
				});

				base = base.add(1, 'days');

			}
			
			const groupedDatas = {
				out_days: _.mapValues(_.groupBy(out_days, 'week'), function(u) {
					return _.groupBy(u, 'weekDay')
				}),
				cal,
				min: _.min(days),
				max: _.max(days)
			}
						
			return groupedDatas
		},
		auth: function() {
			return Auth;
		},
	},
}
</script>