<template>
	<b-card title="Index giocate utili">
		<b-table-simple small striped bordered style="font-size:0.9rem">
			<thead>
				<tr>
					<th colspan="2"><a href="" @click.prevent="setOrdering('player')">Player</a></th>
					<th><a href="" @click.prevent="setOrdering('minutes')">Minutes</a> <span v-if="'minutes' == ordering.key">{{ ordering.order == -1 ? '↥' : '↧' }}</span></th>
					<th><a href="" @click.prevent="setOrdering('blpGround123')">BLP Ground 1-2-3</a> <span v-if="'blpGround123' == ordering.key">{{ ordering.order == -1 ? '↥' : '↧' }}</span></th>
					<th><a href="" @click.prevent="setOrdering('dribblingSucc')">1V1 riusciti</a> <span v-if="'dribblingSucc' == ordering.key">{{ ordering.order == -1 ? '↥' : '↧' }}</span></th>
					<th><a href="" @click.prevent="setOrdering('shotsFromIndividualPlay')">Shots from individual play</a> <span v-if="'shotsFromIndividualPlay' == ordering.key">{{ ordering.order == -1 ? '↥' : '↧' }}</span></th>
					<th><a href="" @click.prevent="setOrdering('progCarry')">Prog Carry</a> <span v-if="'progCarry' == ordering.key">{{ ordering.order == -1 ? '↥' : '↧' }}</span></th>
					<th><a href="" @click.prevent="setOrdering('filtrante')">Filtrante</a> <span v-if="'filtrante' == ordering.key">{{ ordering.order == -1 ? '↥' : '↧' }}</span></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="player, index in attaccanti">
					<td v-if="index == 0" style="vertical-align: middle;background: #FFFFFF;" class="text-center" :rowspan="attaccanti.length">Attaccante</td>
					<td>{{ player.firstName + ' ' + player.lastName }}</td>
					<td>{{ player.minutes }}</td>
					<td :style="'background:' + getBgValueStats('blpGround123', 'Attaccante', player.blpGround123)">{{ player.blpGround123 || '' }}</td>
					<td :style="'background:' + getBgValueStats('dribblingSucc', 'Attaccante', player.dribblingSucc)">{{ player.dribblingSucc || ''  }}</td>
					<td :style="'background:' + getBgValueStats('shotsFromIndividualPlay', 'Attaccante', player.shotsFromIndividualPlay)">{{ player.shotsFromIndividualPlay || '' }}</td>
					<td :style="'background:' + getBgValueStats('progCarry', 'Attaccante', player.progCarry)">{{ player.progCarry || '' }}</td>
					<td :style="'background:' + getBgValueStats('filtrante', 'Attaccante', player.filtrante)">{{ player.filtrante || '' }}</td>
				</tr>
				<tr><td style="background: #FFFFFF" colspan="8"></td></tr>
				<tr v-for="player, index in centrocampisti">
					<td v-if="index == 0" style="vertical-align: middle;background: #FFFFFF;" class="text-center" :rowspan="centrocampisti.length">Centrocampista</td>
					<td>{{ player.firstName + ' ' + player.lastName }}</td>
					<td>{{ player.minutes }}</td>
					<td :style="'background:' + getBgValueStats('blpGround123', 'Centrocampista', player.blpGround123)">{{ player.blpGround123 || '' }}</td>
					<td :style="'background:' + getBgValueStats('dribblingSucc', 'Centrocampista', player.dribblingSucc)">{{ player.dribblingSucc || ''  }}</td>
					<td :style="'background:' + getBgValueStats('shotsFromIndividualPlay', 'Centrocampista', player.shotsFromIndividualPlay)">{{ player.shotsFromIndividualPlay || '' }}</td>
					<td :style="'background:' + getBgValueStats('progCarry', 'Centrocampista', player.progCarry)">{{ player.progCarry || '' }}</td>
					<td :style="'background:' + getBgValueStats('filtrante', 'Centrocampista', player.filtrante)">{{ player.filtrante || '' }}</td>
				</tr>
				<tr><td style="background: #FFFFFF" colspan="8"></td></tr>
				<tr v-for="player, index in difensori">
					<td v-if="index == 0" style="vertical-align: middle;background: #FFFFFF;" class="text-center" :rowspan="difensori.length">Difensore</td>
					<td>{{ player.firstName + ' ' + player.lastName }}</td>
					<td>{{ player.minutes }}</td>
					<td :style="'background:' + getBgValueStats('blpGround123', 'Difensore', player.blpGround123)">{{ player.blpGround123 || '' }}</td>
					<td :style="'background:' + getBgValueStats('dribblingSucc', 'Difensore', player.dribblingSucc)">{{ player.dribblingSucc || ''  }}</td>
					<td :style="'background:' + getBgValueStats('shotsFromIndividualPlay', 'Difensore', player.shotsFromIndividualPlay)">{{ player.shotsFromIndividualPlay || '' }}</td>
					<td :style="'background:' + getBgValueStats('progCarry', 'Difensore', player.progCarry)">{{ player.progCarry || '' }}</td>
					<td :style="'background:' + getBgValueStats('filtrante', 'Difensore', player.filtrante)">{{ player.filtrante || '' }}</td>
				</tr>
			</tbody>
		</b-table-simple>
	</b-card>
</template>

<script>
import moment from 'moment'
export default {
	props: ['stats', 'average'],
	data: function() {
		return {
			ordering: { key: 'player', order: -1 },
		}
	},
	methods: {
		setOrdering: function(key) {
			if(this.ordering.key != key) {
				this.ordering.key = key;
				this.ordering.order = -1;
			} else {
				this.ordering.order *= -1;
			}
		},
		hsl_col_perc(percent, start, end) {
			if(percent > 100) {
				percent = 100;
			}
			var a = percent / 100,
			b = (end - start) * a,
			c = b + start;
			return 'hsl(' + c + ', 100%, 50%, 0.8)';
		},
		getBgValueStats(kpi, position, value) {
			var avg = this.average[position];
			if(value && avg && avg[kpi]) {
			var diffPerc = ((value - avg[kpi])/avg[kpi]) * 100;
				if(diffPerc >= 0) {	
					return this.hsl_col_perc(diffPerc, 75, 135);
				} else {
					return this.hsl_col_perc(-diffPerc, 60, 0);
				}
			}
			return '';
		},
	},
	computed: {
		players: function() {
			return _.filter((this.stats && this.stats.players && this.average ? this.stats.players : []), function (item) {
        		return item.minutes;
    		});
		},
		difensori: function() {
			if(this.ordering.key == 'player') 
				return this.players.filter(item => item.genericPosition == 'Difensore');
			return _.orderBy(this.players.filter(item => item.genericPosition == 'Difensore'), [this.ordering.key],[this.ordering.order == -1 ? 'asc' : 'desc']);
		},
		centrocampisti: function() {
			if(this.ordering.key == 'player') 
				return this.players.filter(item => item.genericPosition == 'Centrocampista');
			return _.orderBy(this.players.filter(item => item.genericPosition == 'Centrocampista'), [this.ordering.key],[this.ordering.order == -1 ? 'asc' : 'desc']);
		},
		attaccanti: function() {
			if(this.ordering.key == 'player') 
				return this.players.filter(item => item.genericPosition == 'Attaccante');
			return _.orderBy(this.players.filter(item => item.genericPosition == 'Attaccante'), [this.ordering.key],[this.ordering.order == -1 ? 'asc' : 'desc']);
		},
	},
}
</script>
