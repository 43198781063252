<template>
	<b-card title="Player stats">

		<div class="text-center">Goal e Assist</div>
		<b-table-simple small striped bordered responsive style="font-size:0.9rem">
			<thead>
				<tr>
					<th rowspan="3"><a href="" @click.prevent="setOrdering('goals', 'player')">Player</a></th>
					<th rowspan="3" class="text-center" style="vertical-align: middle;">Totale complessivo</th>
					<th class="text-center" :colspan="attaccanti.length">Attaccante</th>
					<th class="text-center"></th>
					<th class="text-center" :colspan="centrocampisti.length">Centrocampista</th>
					<th class="text-center"></th>
					<th class="text-center" :colspan="difensori.length">Difensore</th>
				</tr>
				<tr>
					<th class="text-center" v-for="player in goals_attaccanti">{{ player.player }}</th>
					<th class="text-center"></th>
					<th class="text-center" v-for="player in goals_centrocampisti">{{ player.player }}</th>
					<th class="text-center"></th>
					<th class="text-center" v-for="player in goals_difensori">{{ player.player }}</th>
				</tr>
				<tr>
					<td class="text-center" v-for="player in goals_attaccanti">{{ player.minutes }} min.</td>
					<th class="text-center"></th>
					<td class="text-center" v-for="player in goals_centrocampisti">{{ player.minutes }} min.</td>
					<th class="text-center"></th>
					<td class="text-center" v-for="player in goals_difensori">{{ player.minutes }} min.</td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td style="min-width:200px;border-bottom: 2px #000000 solid;"><a href="" @click.prevent="setOrdering('goals', 'goalOP')">Goal OP</a> <span v-if="'goalOP' == orderings.goals.key">{{ orderings.goals.order == -1 ? '↤' : '↦' }}</span></td>
					<td style="border-bottom: 2px #000000 solid;" class="text-center">{{ stats && stats.total ? (stats.total.goalOP || '') : '' }}</td>
					<td style="border-bottom: 2px #000000 solid;" class="text-center" v-for="player in goals_attaccanti" :style="'background:' + getBgValueStats('goalOP', 'Attaccante', player.goalOP)">{{ player.goalOP || '' }}</td>
					<td style="background: #FFFFFF;"></td>
					<td style="border-bottom: 2px #000000 solid;" class="text-center" v-for="player in goals_centrocampisti" :style="'background:' + getBgValueStats('goalOP', 'Centrocampista', player.goalOP)">{{ player.goalOP || '' }}</td>
					<td style="background: #FFFFFF;"></td>
					<td style="border-bottom: 2px #000000 solid;" class="text-center" v-for="player in goals_difensori" :style="'background:' + getBgValueStats('goalOP', 'Difensore', player.goalOP)">{{ player.goalOP || '' }}</td>
				</tr>
				<tr>
					<td style="min-width:200px;"><a href="" @click.prevent="setOrdering('goals', 'goalSetPieces_IND')">GoalSetPieces_IND</a> <span v-if="'goalSetPieces_IND' == orderings.goals.key">{{ orderings.goals.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats && stats.total ? (stats.total.goalSetPieces_IND || '') : '' }}</td>
					<td class="text-center" v-for="player in goals_attaccanti" :style="'background:' + getBgValueStats('goalSetPieces_IND', 'Attaccante', player.goalSetPieces_IND)">{{ player.goalSetPieces_IND || '' }}</td>
					<td style="background: #FFFFFF;"></td>
					<td class="text-center" v-for="player in goals_centrocampisti" :style="'background:' + getBgValueStats('goalSetPieces_IND', 'Centrocampista', player.goalSetPieces_IND)">{{ player.goalSetPieces_IND || '' }}</td>
					<td style="background: #FFFFFF;"></td>
					<td class="text-center" v-for="player in goals_difensori" :style="'background:' + getBgValueStats('goalSetPieces_IND', 'Difensore', player.goalSetPieces_IND)">{{ player.goalSetPieces_IND || '' }}</td>
				</tr>
				<tr>
					<td style="min-width:200px;"><a href="" @click.prevent="setOrdering('goals', 'goalDirFK')">GoalDirFK</a> <span v-if="'goalDirFK' == orderings.goals.key">{{ orderings.goals.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats && stats.total ? (stats.total.goalDirFK || '') : '' }}</td>
					<td class="text-center" v-for="player in goals_attaccanti" :style="'background:' + getBgValueStats('goalDirFK', 'Attaccante', player.goalDirFK)">{{ player.goalDirFK || '' }}</td>
					<td style="background: #FFFFFF;"></td>
					<td class="text-center" v-for="player in goals_centrocampisti" :style="'background:' + getBgValueStats('goalDirFK', 'Centrocampista', player.goalDirFK)">{{ player.goalDirFK || '' }}</td>
					<td style="background: #FFFFFF;"></td>
					<td class="text-center" v-for="player in goals_difensori" :style="'background:' + getBgValueStats('goalDirFK', 'Difensore', player.goalDirFK)">{{ player.goalDirFK || '' }}</td>
				</tr>
				<tr>
					<td style="min-width:200px;"><a href="" @click.prevent="setOrdering('goals', 'penGoal')">PenGoal</a> <span v-if="'penGoal' == orderings.goals.key">{{ orderings.goals.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats && stats.total ? (stats.total.penGoal || '') : '' }}</td>
					<td class="text-center" v-for="player in goals_attaccanti" :style="'background:' + getBgValueStats('penGoal', 'Attaccante', player.penGoal)">{{ player.penGoal || '' }}</td>
					<td style="background: #FFFFFF;"></td>
					<td class="text-center" v-for="player in goals_centrocampisti" :style="'background:' + getBgValueStats('penGoal', 'Centrocampista', player.penGoal)">{{ player.penGoal || '' }}</td>
					<td style="background: #FFFFFF;"></td>
					<td class="text-center" v-for="player in goals_difensori" :style="'background:' + getBgValueStats('penGoal', 'Difensore', player.penGoal)">{{ player.penGoal || '' }}</td>
				</tr>
				<tr>
					<td style="min-width:200px;"><a href="" @click.prevent="setOrdering('goals', 'intentAstByOP')">IntentAst by OP</a> <span v-if="'intentAstByOP' == orderings.goals.key">{{ orderings.goals.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats && stats.total ? (stats.total.intentAstByOP || '') : '' }}</td>
					<td class="text-center" v-for="player in goals_attaccanti" :style="'background:' + getBgValueStats('intentAstByOP', 'Attaccante', player.intentAstByOP)">{{ player.intentAstByOP || '' }}</td>
					<td style="background: #FFFFFF;"></td>
					<td class="text-center" v-for="player in goals_centrocampisti" :style="'background:' + getBgValueStats('intentAstByOP', 'Centrocampista', player.intentAstByOP)">{{ player.intentAstByOP || '' }}</td>
					<td style="background: #FFFFFF;"></td>
					<td class="text-center" v-for="player in goals_difensori" :style="'background:' + getBgValueStats('intentAstByOP', 'Difensore', player.intentAstByOP)">{{ player.intentAstByOP || '' }}</td>
				</tr>
				<tr>
					<td style="min-width:200px;"><a href="" @click.prevent="setOrdering('goals', 'intentAstBySP')">IntentAst by SP</a> <span v-if="'intentAstBySP' == orderings.goals.key">{{ orderings.goals.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center">{{ stats && stats.total ? (stats.total.intentAstBySP || '') : '' }}</td>
					<td class="text-center" v-for="player in goals_attaccanti" :style="'background:' + getBgValueStats('intentAstBySP', 'Attaccante', player.intentAstBySP)">{{ player.intentAstBySP || '' }}</td>
					<td style="background: #FFFFFF;"></td>
					<td class="text-center" v-for="player in goals_centrocampisti" :style="'background:' + getBgValueStats('intentAstBySP', 'Centrocampista', player.intentAstBySP)">{{ player.intentAstBySP || '' }}</td>
					<td style="background: #FFFFFF;"></td>
					<td class="text-center" v-for="player in goals_difensori" :style="'background:' + getBgValueStats('intentAstBySP', 'Difensore', player.intentAstBySP)">{{ player.intentAstBySP || '' }}</td>
				</tr>
			</tbody>
		</b-table-simple>

		<b-row>
			<b-col cols="8">
				<div class="text-center">Skills offensive</div>
				<b-table-simple small striped bordered responsive style="font-size:0.9rem">
					<thead>
						<tr>
							<th colspan="2"><a href="" @click.prevent="setOrdering('offensive', 'player')">Player</a></th>
							<th class="text-center"><a href="" @click.prevent="setOrdering('offensive', 'vertAttfrom3D')">Verticalizzazione da 3D</a> <span v-if="'vertAttfrom3D' == orderings.offensive.key">{{ orderings.offensive.order == -1 ? '↥' : '↧' }}</span></th>
							<th class="text-center"><a href="" @click.prevent="setOrdering('offensive', 'breakLinePass')">Break Line Pass</a> <span v-if="'breakLinePass' == orderings.offensive.key">{{ orderings.offensive.order == -1 ? '↥' : '↧' }}</span></th>
							<th class="text-center"><a href="" @click.prevent="setOrdering('offensive', 'progCarry')">Prog Carry</a> <span v-if="'progCarry' == orderings.offensive.key">{{ orderings.offensive.order == -1 ? '↥' : '↧' }}</span></th>
							<th class="text-center"><a href="" @click.prevent="setOrdering('offensive', 'progPass')">Prog Pass</a> <span v-if="'progPass' == orderings.offensive.key">{{ orderings.offensive.order == -1 ? '↥' : '↧' }}</span></th>
							<th class="text-center"><a href="" @click.prevent="setOrdering('offensive', 'crosses')">Cross</a> <span v-if="'crosses' == orderings.offensive.key">{{ orderings.offensive.order == -1 ? '↥' : '↧' }}</span></th>
							<th class="text-center"><a href="" @click.prevent="setOrdering('offensive', 'dribbling')">Dribbling</a> <span v-if="'dribbling' == orderings.offensive.key">{{ orderings.offensive.order == -1 ? '↥' : '↧' }}</span></th>
							<th class="text-center"><a href="" @click.prevent="setOrdering('offensive', 'filtrante')">Filtrante</a> <span v-if="'filtrante' == orderings.offensive.key">{{ orderings.offensive.order == -1 ? '↥' : '↧' }}</span></th>
							<th class="text-center"><a href="" @click.prevent="setOrdering('offensive', 'duelliAereiOffensivi')">Duelli aerei offensivi</a> <span v-if="'duelliAereiOffensivi' == orderings.offensive.key">{{ orderings.offensive.order == -1 ? '↥' : '↧' }}</span></th>
							<th class="text-center"><a href="" @click.prevent="setOrdering('offensive', 'shotsOutBoxNoBlocksOpenPlay')">Shots OutBox (no blocks) Open Play</a> <span v-if="'shotsOutBoxNoBlocksOpenPlay' == orderings.offensive.key">{{ orderings.offensive.order == -1 ? '↥' : '↧' }}</span></th>
							<th class="text-center"><a href="" @click.prevent="setOrdering('offensive', 'xGFromOP')">XG Open Play</a> <span v-if="'xGFromOP' == orderings.offensive.key">{{ orderings.offensive.order == -1 ? '↥' : '↧' }}</span></th>
							<th class="text-center"><a href="" @click.prevent="setOrdering('offensive', 'xGAereiSetPieces')">XG aerei su SetPieces</a> <span v-if="'xGAereiSetPieces' == orderings.offensive.key">{{ orderings.offensive.order == -1 ? '↥' : '↧' }}</span></th>
							<th class="text-center"><a href="" @click.prevent="setOrdering('offensive', 'xGFromSetPieces_IND')">XG total SetPieces IND</a> <span v-if="'xGFromSetPieces_IND' == orderings.offensive.key">{{ orderings.offensive.order == -1 ? '↥' : '↧' }}</span></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="player, index in offensive_attaccanti">
							<td v-if="index == 0" style="vertical-align: middle; background: #FFFFFF;" class="text-center" :rowspan="attaccanti.length">Attaccante</td>
							<td>{{ player.player }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('vertAttfrom3D', 'Attaccante', player.vertAttfrom3D)">{{ player.vertAttfrom3D || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('breakLinePass', 'Attaccante', player.breakLinePass)">{{ player.breakLinePass || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('progCarry', 'Attaccante', player.progCarry)">{{ player.progCarry || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('progPass', 'Attaccante', player.progPass)">{{ player.progPass || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('crosses', 'Attaccante', player.crosses)">{{ player.crosses || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('dribbling', 'Attaccante', player.dribbling)">{{ player.dribbling || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('filtrante', 'Attaccante', player.filtrante)">{{ player.filtrante || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('duelliAereiOffensivi', 'Attaccante', player.duelliAereiOffensivi)">{{ player.duelliAereiOffensivi || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('shotsOutBoxNoBlocksOpenPlay', 'Attaccante', player.shotsOutBoxNoBlocksOpenPlay)">{{ player.shotsOutBoxNoBlocksOpenPlay || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('xGFromOP', 'Attaccante', player.xGFromOP)">{{ player.xGFromOP || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('xGAereiSetPieces', 'Attaccante', player.xGAereiSetPieces)">{{ player.xGAereiSetPieces || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('xGFromSetPieces_IND', 'Attaccante', player.xGFromSetPieces_IND)">{{ player.xGFromSetPieces_IND || '' }}</td>
						</tr>
						<tr><td style="background: #FFFFFF" colspan="14"></td></tr>
						<tr v-for="player, index in offensive_centrocampisti">
							<td v-if="index == 0" style="vertical-align: middle; background: #FFFFFF;" class="text-center" :rowspan="centrocampisti.length">Centrocampista</td>
							<td>{{ player.player }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('vertAttfrom3D', 'Centrocampista', player.vertAttfrom3D)">{{ player.vertAttfrom3D || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('breakLinePass', 'Centrocampista', player.breakLinePass)">{{ player.breakLinePass || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('progCarry', 'Centrocampista', player.progCarry)">{{ player.progCarry || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('progPass', 'Centrocampista', player.progPass)">{{ player.progPass || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('crosses', 'Centrocampista', player.crosses)">{{ player.crosses || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('dribbling', 'Centrocampista', player.dribbling)">{{ player.dribbling || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('filtrante', 'Centrocampista', player.filtrante)">{{ player.filtrante || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('duelliAereiOffensivi', 'Centrocampista', player.duelliAereiOffensivi)">{{ player.duelliAereiOffensivi || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('shotsOutBoxNoBlocksOpenPlay', 'Centrocampista', player.shotsOutBoxNoBlocksOpenPlay)">{{ player.shotsOutBoxNoBlocksOpenPlay || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('xGFromOP', 'Centrocampista', player.xGFromOP)">{{ player.xGFromOP || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('xGAereiSetPieces', 'Centrocampista', player.xGAereiSetPieces)">{{ player.xGAereiSetPieces || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('xGFromSetPieces_IND', 'Centrocampista', player.xGFromSetPieces_IND)">{{ player.xGFromSetPieces_IND || '' }}</td>
						</tr>
						<tr><td style="background: #FFFFFF" colspan="14"></td></tr>
						<tr v-for="player, index in offensive_difensori">
							<td v-if="index == 0" style="vertical-align: middle; background: #FFFFFF;" class="text-center" :rowspan="difensori.length">Difensore</td>
							<td>{{ player.player }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('vertAttfrom3D', 'Difensore', player.vertAttfrom3D)">{{ player.vertAttfrom3D || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('breakLinePass', 'Difensore', player.breakLinePass)">{{ player.breakLinePass || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('progCarry', 'Difensore', player.progCarry)">{{ player.progCarry || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('progPass', 'Difensore', player.progPass)">{{ player.progPass || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('crosses', 'Difensore', player.crosses)">{{ player.crosses || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('dribbling', 'Difensore', player.dribbling)">{{ player.dribbling || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('filtrante', 'Difensore', player.filtrante)">{{ player.filtrante || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('duelliAereiOffensivi', 'Difensore', player.duelliAereiOffensivi)">{{ player.duelliAereiOffensivi || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('shotsOutBoxNoBlocksOpenPlay', 'Difensore', player.shotsOutBoxNoBlocksOpenPlay)">{{ player.shotsOutBoxNoBlocksOpenPlay || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('xGFromOP', 'Difensore', player.xGFromOP)">{{ player.xGFromOP || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('xGAereiSetPieces', 'Difensore', player.xGAereiSetPieces)">{{ player.xGAereiSetPieces || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('xGFromSetPieces_IND', 'Difensore', player.xGFromSetPieces_IND)">{{ player.xGFromSetPieces_IND || '' }}</td>
						</tr>
					</tbody>
				</b-table-simple>
				<div class="text-center">Skills difensive</div>
				<b-table-simple small striped bordered responsive style="font-size:0.9rem">
					<thead>
						<tr>
							<th colspan="2"><a href="" @click.prevent="setOrdering('difensive', 'player')">Player</a></th>
							<th class="text-center"><a href="" @click.prevent="setOrdering('difensive', 'duelliAereiDifensivi')">Duello aereo difensivo</a> <span v-if="'duelliAereiDifensivi' == orderings.difensive.key">{{ orderings.difensive.order == -1 ? '↥' : '↧' }}</span></th>
							<th class="text-center"><a href="" @click.prevent="setOrdering('difensive', 'intercettiMetaDifensiva')">Intercetti metà difensiva</a> <span v-if="'intercettiMetaDifensiva' == orderings.difensive.key">{{ orderings.difensive.order == -1 ? '↥' : '↧' }}</span></th>
							<th class="text-center"><a href="" @click.prevent="setOrdering('difensive', 'intercettiMetaOffensiva')">Intercetti metà offensiva</a> <span v-if="'intercettiMetaOffensiva' == orderings.difensive.key">{{ orderings.difensive.order == -1 ? '↥' : '↧' }}</span></th>
							<th class="text-center"><a href="" @click.prevent="setOrdering('difensive', 'recuperiMetaDifensiva')">Recuperi metà difensiva</a> <span v-if="'recuperiMetaDifensiva' == orderings.difensive.key">{{ orderings.difensive.order == -1 ? '↥' : '↧' }}</span></th>
							<th class="text-center"><a href="" @click.prevent="setOrdering('difensive', 'recuperiMetaOffensiva')">Recuperi metà offensiva</a> <span v-if="'recuperiMetaOffensiva' == orderings.difensive.key">{{ orderings.difensive.order == -1 ? '↥' : '↧' }}</span></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="player, index in difensive_attaccanti">
							<td v-if="index == 0" style="vertical-align: middle; background: #FFFFFF;" class="text-center" :rowspan="attaccanti.length">Attaccante</td>
							<td>{{ player.player }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('duelliAereiDifensivi', 'Attaccante', player.duelliAereiDifensivi)">{{ player.duelliAereiDifensivi || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('intercettiMetaDifensiva', 'Attaccante', player.intercettiMetaDifensiva)">{{ player.intercettiMetaDifensiva || ''}}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('intercettiMetaOffensiva', 'Attaccante', player.intercettiMetaOffensiva)">{{ player.intercettiMetaOffensiva || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('recuperiMetaDifensiva', 'Attaccante', player.recuperiMetaDifensiva)">{{ player.recuperiMetaDifensiva || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('recuperiMetaOffensiva', 'Attaccante', player.recuperiMetaOffensiva)">{{ player.recuperiMetaOffensiva || '' }}</td>
						</tr>
						<tr><td style="background: #FFFFFF" colspan="7"></td></tr>
						<tr v-for="player, index in difensive_centrocampisti">
							<td v-if="index == 0" style="vertical-align: middle; background: #FFFFFF;" class="text-center" :rowspan="centrocampisti.length">Centrocampista</td>
							<td>{{ player.player }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('duelliAereiDifensivi', 'Centrocampista', player.duelliAereiDifensivi)">{{ player.duelliAereiDifensivi || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('intercettiMetaDifensiva', 'Centrocampista', player.intercettiMetaDifensiva)">{{ player.intercettiMetaDifensiva || ''}}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('intercettiMetaOffensiva', 'Centrocampista', player.intercettiMetaOffensiva)">{{ player.intercettiMetaOffensiva || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('recuperiMetaDifensiva', 'Centrocampista', player.recuperiMetaDifensiva)">{{ player.recuperiMetaDifensiva || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('recuperiMetaOffensiva', 'Centrocampista', player.recuperiMetaOffensiva)">{{ player.recuperiMetaOffensiva || '' }}</td>
						</tr>
						<tr><td style="background: #FFFFFF" colspan="7"></td></tr>
						<tr v-for="player, index in difensive_difensori">
							<td v-if="index == 0" style="vertical-align: middle; background: #FFFFFF;" class="text-center" :rowspan="difensori.length">Difensore</td>
							<td>{{ player.player }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('duelliAereiDifensivi', 'Difensore', player.duelliAereiDifensivi)">{{ player.duelliAereiDifensivi || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('intercettiMetaDifensiva', 'Difensore', player.intercettiMetaDifensiva)">{{ player.intercettiMetaDifensiva || ''}}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('intercettiMetaOffensiva', 'Difensore', player.intercettiMetaOffensiva)">{{ player.intercettiMetaOffensiva || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('recuperiMetaDifensiva', 'Difensore', player.recuperiMetaDifensiva)">{{ player.recuperiMetaDifensiva || '' }}</td>
							<td class="text-center" :style="'background:' + getBgValueStats('recuperiMetaOffensiva', 'Difensore', player.recuperiMetaOffensiva)">{{ player.recuperiMetaOffensiva || '' }}</td>
						</tr>
					</tbody>
				</b-table-simple>
			</b-col>
			<b-col>
				<div class="text-center">Valori condizionali (TODO)</div>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
export default {
	props: ['stats', 'average'],
	data: function() {
		return {
			orderings: {
				goals: { key: 'player', order: -1 },
				offensive: { key: 'player', order: -1 },
				difensive: { key: 'player', order: -1 },
			},

		}
	},
	methods: {
		setOrdering: function(list, key) {
			if(this.orderings[list].key != key) {
				this.orderings[list].key = key;
				this.orderings[list].order = -1;
			} else {
				this.orderings[list].order *= -1;
			}
		},
		hsl_col_perc(percent, start, end) {
			if(percent > 100) {
				percent = 100;
			}
			var a = percent / 100,
			b = (end - start) * a,
			c = b + start;
			return 'hsl(' + c + ', 100%, 50%, 0.8)';
		},
		getBgValueStats(kpi, position, value) {
			var avg = this.average[position];
			if(value && avg && avg[kpi]) {
			var diffPerc = ((value - avg[kpi])/avg[kpi]) * 100;
				if(diffPerc >= 0) {	
					return this.hsl_col_perc(diffPerc, 75, 135);
				} else {
					return this.hsl_col_perc(-diffPerc, 60, 0);
				}
			}
			return '';
		},
	},
	computed: {
		players: function() {
			return _.filter((this.stats && this.stats.players && this.average ? this.stats.players : []), function (item) {
        		return item.minutes;
    		});
		},
		difensori: function() {
			return this.players.filter(item => item.genericPosition == 'Difensore');
		},
		centrocampisti: function() {
			return this.players.filter(item => item.genericPosition == 'Centrocampista');
		},
		attaccanti: function() {
			return this.players.filter(item => item.genericPosition == 'Attaccante');
		},
		goals_difensori: function() {
			if(this.orderings.goals.key == 'player') {
				return this.difensori;
			}
			return _.orderBy(this.difensori, [this.orderings.goals.key], [this.orderings.goals.order == -1 ? 'asc' : 'desc']);
		},
		goals_centrocampisti: function() {
			if(this.orderings.goals.key == 'player') {
				return this.centrocampisti;
			}
			return _.orderBy(this.centrocampisti, [this.orderings.goals.key], [this.orderings.goals.order == -1 ? 'asc' : 'desc']);
		},
		goals_attaccanti: function() {
			if(this.orderings.goals.key == 'player') {
				return this.attaccanti;
			}
			return _.orderBy(this.attaccanti, [this.orderings.goals.key], [this.orderings.goals.order == -1 ? 'asc' : 'desc']);
		},
		offensive_difensori: function() {
			if(this.orderings.offensive.key == 'player') {
				return this.difensori;
			}
			return _.orderBy(this.difensori, [this.orderings.offensive.key], [this.orderings.offensive.order == -1 ? 'asc' : 'desc']);
		},
		offensive_centrocampisti: function() {
			if(this.orderings.offensive.key == 'player') {
				return this.centrocampisti;
			}
			return _.orderBy(this.centrocampisti, [this.orderings.offensive.key], [this.orderings.offensive.order == -1 ? 'asc' : 'desc']);
		},
		offensive_attaccanti: function() {
			if(this.orderings.offensive.key == 'player') {
				return this.attaccanti;
			}
			return _.orderBy(this.attaccanti, [this.orderings.offensive.key], [this.orderings.offensive.order == -1 ? 'asc' : 'desc']);
		},
		difensive_difensori: function() {
			if(this.orderings.difensive.key == 'player') {
				return this.difensori;
			}
			return _.orderBy(this.difensori, [this.orderings.difensive.key], [this.orderings.difensive.order == -1 ? 'asc' : 'desc']);
		},
		difensive_centrocampisti: function() {
			if(this.orderings.difensive.key == 'player') {
				return this.centrocampisti;
			}
			return _.orderBy(this.centrocampisti, [this.orderings.difensive.key], [this.orderings.difensive.order == -1 ? 'asc' : 'desc']);
		},
		difensive_attaccanti: function() {
			if(this.orderings.difensive.key == 'player') {
				return this.attaccanti;
			}
			return _.orderBy(this.attaccanti, [this.orderings.difensive.key], [this.orderings.difensive.order == -1 ? 'asc' : 'desc']);
		},
	},
}
</script>
