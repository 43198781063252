<template>
	<b-card title="Valori difensivi per match">
		<b-table-simple small striped bordered responsive style="font-size:0.9rem">
			<thead>
				<tr>
					<th style="min-width:200px;"><a href="" @click.prevent="setOrdering('date')">Game</a> <span v-if="'date' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></th>
					<th class="text-center" v-for="match in myMatches">{{ match.game }} <br/> {{ match.date | formatDate }}</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td style="min-width:200px;"><a href="" @click.prevent="setOrdering('avgSeqTimeAfterRecovery')">AvgSeqTimeAfterRecovery</a> <span v-if="'avgSeqTimeAfterRecovery' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center" v-for="match in myMatches" :style="'background:' + getBgValueStats('avgSeqTimeAfterRecovery', match.avgSeqTimeAfterRecovery)">{{ match.avgSeqTimeAfterRecovery }}</td>
				</tr>
				<tr>
					<td style="min-width:200px;"><a href="" @click.prevent="setOrdering('ppda')">PPDA</a> <span v-if="'ppda' == ordering.key">{{ ordering.order == -1 ? '↤' : '↦' }}</span></td>
					<td class="text-center" v-for="match in myMatches" :style="'background:' + getBgValueStats('ppda', match.ppda)">{{ match.ppda }}</td>
				</tr>
			</tbody>
		</b-table-simple>
	</b-card>
</template>

<script>
import moment from 'moment'
export default {
	props: ['stats', 'team', 'average'],
	data: function() {
		return {
			ordering: { key: 'game', order: -1 },
		}
	},
	methods: {
		setOrdering: function(key) {
			if(this.ordering.key != key) {
				this.ordering.key = key;
				this.ordering.order = -1;
			} else {
				this.ordering.order *= -1;
			}
		},
		hsl_col_perc(percent, start, end) {
			if(percent > 100) {
				percent = 100;
			}
			var a = percent / 100,
			b = (end - start) * a,
			c = b + start;
			return 'hsl(' + c + ', 100%, 50%, 0.8)';
		},
		getBgValueStats(kpi, value) {
			var diffPerc = ((value - this.average.average[kpi])/this.average.average[kpi]) * 100;
			if(diffPerc >= 0) {
				return this.hsl_col_perc(diffPerc, 60, 0);
			} else {
				return this.hsl_col_perc(-diffPerc, 75, 135);	
			}
		},

	},
	computed: {
		matches: function () {
			return this.stats && this.stats.games ? this.stats.games : [];
		},
		myMatches: function () {
			return _.orderBy(this.matches, [this.ordering.key],[this.ordering.order == -1 ? 'asc' : 'desc']);
		},
	},
	filters: {
		formatDate: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.format('DD/MM/YYYY'); 
			}
			return "";
		}
	},
}
</script>
